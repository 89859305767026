import {
  useGetSavedClassesQuery,
  useGetSavedProgramsQuery,
} from "services/graphql";
import { useParams } from "react-router-dom";
import { MenuOptions } from "./types";

export function useGetSavedClasses() {
  const perPage = 10;

  const {
    data,
    fetchMore: fetchMoreData,
    loading: loadingSavedClasses,
    error: errorSavedClasses,
  } = useGetSavedClassesQuery({
    variables: {
      first: perPage,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const pageInfoSavedClasses = data?.savedClasses?.pageInfo;
  const savedClasses = data?.savedClasses?.edges.map(edge => edge.node);

  const fetchMoreSavedClasses = () => {
    fetchMoreData({
      variables: {
        first: perPage,
        after: pageInfoSavedClasses?.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previousEntry = previousResult.savedClasses;
        const newEdges = fetchMoreResult.savedClasses.edges;

        return {
          savedClasses: {
            ...fetchMoreResult.savedClasses,
            edges: [...previousEntry.edges, ...newEdges],
          },
        };
      },
    });
  };

  return {
    isFetchingInitialSavedClasses: !savedClasses && loadingSavedClasses,
    loadingSavedClasses,
    errorSavedClasses,
    savedClasses,
    pageInfoSavedClasses,
    fetchMoreSavedClasses,
  };
}

export function useGetSavedPrograms() {
  const perPage = 10;

  const {
    data,
    fetchMore: fetchMoreData,
    loading: loadingSavedPrograms,
    error: errorSavedPrograms,
  } = useGetSavedProgramsQuery({
    variables: {
      first: perPage,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const pageInfoSavedPrograms = data?.savedPrograms?.pageInfo;
  const savedPrograms = data?.savedPrograms?.edges.map(edge => edge.node);

  const fetchMoreSavedPrograms = () => {
    fetchMoreData({
      variables: {
        first: perPage,
        after: pageInfoSavedPrograms?.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previousEntry = previousResult.savedPrograms;
        const newEdges = fetchMoreResult.savedPrograms.edges;

        return {
          savedPrograms: {
            ...fetchMoreResult.savedPrograms,
            edges: [...previousEntry.edges, ...newEdges],
          },
        };
      },
    });
  };

  return {
    isFetchingInitialSavedPrograms: !savedPrograms && loadingSavedPrograms,
    loadingSavedPrograms,
    errorSavedPrograms,
    savedPrograms,
    pageInfoSavedPrograms,
    fetchMoreSavedPrograms,
  };
}

type SavedParams = {
  menuOption: MenuOptions;
};

export const useGetMenuOption = () => {
  const { menuOption: selectedOption } = useParams<SavedParams>();
  const possibleMenuOptions = Object.values(MenuOptions);
  const isValidMenuOption = possibleMenuOptions.includes(selectedOption);

  return { selectedOption, isValidMenuOption };
};
